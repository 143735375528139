import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField
} from "@material-ui/core";
import {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import syncService from "../../services/async-api/SyncService";
import {Alert} from "@material-ui/lab";

import {Close} from "@material-ui/icons";


export default function SearchDutDialog(props) {
    const [searchDutNumber, setSearchDutNumber] = useState('');
    const [searchError, setSearchError] = useState(false);
    const inputRef = useRef();

    const searchDut = () => {
        let inspectionItemTask = syncService.findInspectionItem(Number.parseInt(searchDutNumber));
        if(inspectionItemTask) {
            props.didFindDut(inspectionItemTask)
        } else {
            setSearchError(true);
        }
    }

    const dutNumberPrefix = (dutNumber) => {
        const missingChars = !dutNumber || !dutNumber.toString().length ? 7:7 - dutNumber.toString().length;

        if (missingChars <= 0) {
            return '';
        }

        return '1' + (new Array(missingChars)).join('0');
    }

    useEffect(() => {
        if(props.open) {
            setSearchDutNumber('');
            setTimeout(() => {
                inputRef.current.focus();
            }, 100)
        }
    },[props.open])

    const handleClose = () => {
        props.handleClose();
    }

    return (
        <Dialog onClose={handleClose} maxWidth={"xs"} fullWidth={true} open={props.open}>
            <DialogTitle>Suche nach Betriebsmittel
                <Button style={{position: "absolute", top: '5px', right: '5px'}} onClick={handleClose} color="primary">
                    <Close style={{fontSize: '35px'}} />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField inputRef={inputRef} type={"number"} fullWidth variant={"outlined"} label={"Betriebsmittelnummer"} value={searchDutNumber}
                           onChange={(evt) => {
                               if (evt.target.value.length > 6) {
                                   return;
                               }

                                setSearchError(false)
                                setSearchDutNumber(evt.target.value)
                            }}
                           onKeyPress={(ev) => {
                               console.log(`Pressed keyCode ${ev.key}`);
                               if (ev.key === 'Enter') {
                                   searchDut();
                                   ev.preventDefault();
                               }
                           }} InputProps={{
                            startAdornment: <InputAdornment position={'start'} style={{marginRight: '0'}}>{dutNumberPrefix(searchDutNumber)}</InputAdornment>
                        }}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            searchError && (
                                <Alert severity="error">Betriebsmittel nicht gefunden</Alert>
                            )
                        }
                    </Grid>
                </Grid>



            </DialogContent>
            <DialogActions>
                <Button disabled={searchDutNumber.length === 0} fullWidth color={"primary"} variant={"contained"} onClick={searchDut}>Suchen</Button>
            </DialogActions>
        </Dialog>
    )
}

SearchDutDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    didFindDut: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
}
